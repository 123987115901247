import { useContext, useEffect, useState } from 'react';
import { HolidayLowerHeader } from '../holiday-common/holiday-lower-header';
import { ClientBackendContext } from '@kidsmanager/ui-api';
import { Booking } from '../holiday-common/holiday-helpers';
import { HolidayBookingItem } from '../holiday-common/holiday-booking-item';
import { Dialog, DialogContext } from '@kidsmanager/ui-core';
import { HolidayNew } from '../holiday-new/holiday-new';
import { IHolidayBooking } from '@kidsmanager/util-models';

const extractOpenBookings = (
  bookings: IHolidayBooking[],
  publicHolidays: Date[],
  today: Date
) => {
  return bookings
    .filter((h) => h.to >= today)
    .map((booking) => {
      return {
        ...booking,
        days: booking.from.workingDaysUntil(booking.to, publicHolidays)
      };
    });
};

export const HolidayViewCurrent = () => {
  const client = useContext(ClientBackendContext);
  const dialog = useContext(DialogContext);
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [publicHolidays, setPublicHolidays] = useState<Date[]>([]);

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    Promise.all([
      client.holiday.bookings(),
      client.holiday.publicHolidays(year - 1, year, year + 1)
    ]).then(([bookings, publicHolidays]) => {
      setBookings(extractOpenBookings(bookings, publicHolidays, today));
      setPublicHolidays(publicHolidays);
    });
  }, [client]);

  const handleRequestMade = async () => {
    dialog.close();
    const bookings = await client.holiday.bookings();
    setBookings(extractOpenBookings(bookings, publicHolidays, new Date()));
  };

  const handleAddHoliday = () => {
    dialog.open(
      <Dialog
        title="Urlaub beantragen"
        width="w-[650px]"
        close={dialog.close.bind(dialog)}
      >
        <HolidayNew onRequestMade={handleRequestMade.bind(this)} />
      </Dialog>
    );
  };

  return (
    <div>
      <HolidayLowerHeader />
      <ul>
        {bookings.map((holiday, index) => (
          <li
            key={index}
            className="border-silver-200 relative ml-4 border-b pb-4 pt-2"
          >
            <HolidayBookingItem
              from={holiday.from}
              to={holiday.to}
              approvedBy={holiday.approvedBy}
              approvedWhen={holiday.approvedWhen}
              days={holiday.days}
            />
          </li>
        ))}
      </ul>
      <div className="px-4 py-8">
        <div
          className="cursor-pointer text-sm underline underline-offset-4"
          onClick={handleAddHoliday.bind(this)}
        >
          + beantragen
        </div>
      </div>
    </div>
  );
};
