export interface MonthDayProps {
  date: Date;
  value: string | number | null;
  mode?: string;
  color: string;
  background: string;
  selected?: boolean;
  onClick?: () => void;
}

export const MonthDay = (props: MonthDayProps) => {
  const isToday = props.date.dateMatches(new Date());

  switch (props.mode) {
    case 'disabled':
      return (
        <div className="border-silver-200 bg-silver-100 min-h-8 rounded-md border text-sm sm:text-base"></div>
      );
    default:
      return (
        <div
          onClick={props.onClick}
          className="relative flex min-h-8 items-center justify-center rounded-md border text-sm sm:text-base"
          style={{
            color: props.selected ? '#ffffff' : props.color,
            background: props.selected
              ? 'var(--color-focus)'
              : props.background,
            borderColor: isToday && !props.selected ? '#fb923c' : '#dfdfdf'
          }}
        >
          {props.value}
        </div>
      );
  }
};
