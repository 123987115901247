import { on } from 'events';
import { useEffect, useMemo, useState } from 'react';

export interface SplitButtonProps {
  children: React.ReactNode;
  options?: string[];
  disabled?: boolean;
  color?: 'primary' | 'default';
  selected?: string;
  onSelect?: (option: string) => void;
  onExpandOptions?: (e: React.MouseEvent) => void;
}

export const SplitButton = (props: SplitButtonProps) => {
  const [selected, setSelected] = useState<string>();
  const [lastSelected, setLastSelected] = useState<string>();
  const [styles, setStyles] = useState('');
  const options = useMemo(() => props.options || [], [props.options]);

  useEffect(() => {
    const index = props.onSelect
      ? options.findIndex((x) => x === props.selected)
      : options.findIndex((x) => x === selected);
    setStyles(
      index >= 0 || props.color === 'primary'
        ? 'border-silver-400 outline-focus bg-primary text-contrast border px-2 py-1 text-sm outline-offset-4'
        : 'border-silver-400 outline-focus border px-2 py-1 text-sm outline-offset-4'
    );
  }, [options, selected, props]);

  const onSelect = (option: string) => {
    if (props.onSelect) {
      props.onSelect?.(option);
      setLastSelected(option);
    } else {
      setSelected(option);
      setLastSelected(option);
    }
    (document.activeElement as HTMLElement)?.blur();
  };

  const onButtonClick = () => {
    const index = props.onSelect
      ? options.findIndex((x) => x === props.selected)
      : options.findIndex((x) => x === selected);

    if (index >= 0) {
      onSelect(options[index]);
    } else {
      const lastIndex = options.findIndex((x) => x === lastSelected);
      onSelect(lastIndex >= 0 ? options[lastIndex] : options[0]);
    }
  };

  const onSplitButtonClick = (e: React.MouseEvent) => {
    if (props.disabled) {
      e.preventDefault();
      return;
    }
    props.onExpandOptions?.(e);
  };

  return (
    <span className="relative inline-flex">
      <button
        className={`${styles} flex-1 rounded-l-md`}
        style={{ opacity: props.disabled ? 0.5 : 1 }}
        disabled={props.disabled}
        onClick={onButtonClick.bind(this)}
      >
        {props.children}
      </button>
      <div
        className={`${styles} group flex cursor-pointer items-center rounded-r-md border-l-0`}
        style={{ opacity: props.disabled ? 0.5 : 1 }}
        onClick={onSplitButtonClick.bind(this)}
        tabIndex={-1}
      >
        <span className="material-icons">arrow_drop_down</span>
        {options.length > 1 && (
          <ul className="shadow-hdr border-silver-300 absolute right-0 top-10 z-50 hidden w-40 border bg-white py-2 group-focus-within:block">
            {options.map((option, index) => (
              <li
                key={index}
                onClick={onSelect.bind(this, option)}
                className="min-w-14 text-nowrap p-2 text-left text-sm text-black outline-none hover:bg-neutral-100"
              >
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
    </span>
  );
};
