import { ApiOauth2 } from './api-oauth2';
import { ApiRoster } from './roster/api-roster';
import { ApiSettings } from './api-settings';
import { ApiUsers } from './api-users';
import { AuthState, IAuth } from './auth';
import { ApiHoliday } from './holiday/api-holiday';
import { ApiAdmin } from './api-admin';
import { ApiTimesheet } from './timesheet/api-timesheet';

export class ClientBackend {
  constructor(public auth: IAuth) {
    this.apiOauth2 = new ApiOauth2(this.auth, this.fetch.bind(this));
    this.apiAdmin = new ApiAdmin(this.auth, this.fetch.bind(this));
    this.settings = new ApiSettings(this.auth, this.fetch.bind(this));
    this.users = new ApiUsers(this.auth, this.fetch.bind(this));
    this.roster = new ApiRoster(this.auth, this.fetch.bind(this));
    this.holiday = new ApiHoliday(this.auth, this.fetch.bind(this));
    this.timesheet = new ApiTimesheet(this.auth, this.fetch.bind(this));
  }

  apiOauth2: ApiOauth2;
  apiAdmin: ApiAdmin;
  settings: ApiSettings;
  users: ApiUsers;
  roster: ApiRoster;
  holiday: ApiHoliday;
  timesheet: ApiTimesheet;

  private tokenRefreshPromise: Promise<AuthState> | null = null;

  private async fetch(url: string, init?: RequestInit): Promise<Response> {
    const expires = this.auth.expiresAt() - 1000 * 60 * 10;
    if (expires < Date.now()) {
      if (!this.tokenRefreshPromise) {
        this.tokenRefreshPromise = this.auth.refresh().finally(() => {
          this.tokenRefreshPromise = null;
        });
      }
      await this.tokenRefreshPromise;
    }

    const token = this.auth.token();
    if (!init) {
      init = {};
    }
    init.headers = {
      ...init.headers,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    };
    return await fetch(url, init);
  }
}
