import { ReactNode, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

export interface DrawerTabItem {
  label: string;
  path: string;
  root?: boolean;
  contextPopup?: ReactNode;
}

export interface DrawerTabsProps {
  tabs: DrawerTabItem[];
}

export const DrawerTabs = (props: DrawerTabsProps) => {
  const location = useLocation();
  const state = useMemo(() => {
    const paths = props.tabs
      .map(({ path }, index) => ({
        index,
        match: location.pathname.startsWith(path),
        distance: Math.abs(location.pathname.length - path.length)
      }))
      .filter((x) => x.match);
    paths.sort((a, b) => a.distance - b.distance);
    const selected = paths[0]?.index ?? 0;
    return { selected, left: `${(paths[0]?.index ?? 0) * 100}%`, count: 0 };
  }, [location.pathname, props.tabs]);

  const calculatePosition = (index: number) => {
    switch (props.tabs.length) {
      case 1:
        return { margin: '0 auto' };
      case 2:
        return index === 0 ? { left: '16%' } : { right: '16%' };
      case 3:
        return index === 0
          ? { left: '10px' }
          : index === 1
            ? { margin: '0 auto' }
            : { right: '10px' };
      default:
        console.warn('Unsupported number of tabs');
        return {};
    }
  };

  const onKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      (document.activeElement as HTMLElement).blur();
      state.count = 0;
    }
  };

  return (
    <div>
      <ul className="relative flex px-0.5 text-sm md:static">
        {props.tabs.map((tab, index) => (
          <li
            key={index}
            tabIndex={-1}
            className={`flex-1 ${state.selected === index && state.count > 0 ? 'group' : ''}`}
            onClick={() => (state.count += 1)}
            onKeyUp={onKeyUp.bind(this)}
          >
            <Link
              className="outline-focus flex h-8 items-center justify-center rounded-t-md py-1 hover:bg-white/10 focus:bg-white/10 focus:outline md:relative"
              style={{
                opacity: state.selected === index ? 1 : 0.6
              }}
              to={tab.path}
            >
              <span className="text-xs uppercase leading-8 tracking-widest md:text-sm">
                {tab.label}
              </span>
              {tab.contextPopup && state.selected === index && (
                <span className="material-icons">arrow_drop_down</span>
              )}

              <div
                className={`absolute top-8 z-10 hidden bg-white shadow-lg group-focus-within:block`}
                style={calculatePosition(index)}
              >
                {tab.contextPopup}
              </div>
            </Link>
          </li>
        ))}
      </ul>
      <div className="relative h-1">
        <div
          className="absolute h-full bg-white transition-transform duration-500 ease-in-out"
          style={{
            width: `${100 / props.tabs.length}%`,
            transform: `translateX(${state.left})`
          }}
        ></div>
      </div>
    </div>
  );
};
