import '@kidsmanager/util-extensions';
import { useEffect, useState } from 'react';
import {
  setDaySelected,
  CalendarMonth,
  findDay,
  DateRange
} from './date-types';
import { generateWeeks } from './date-helpers';
import { DateCalendar } from './date-calendar';
import { IconButton } from '../buttons/icon-button';

export interface DateRangePickerProps {
  stacked?: boolean;
  start: Date;
  publicHolidays: Date[];
  onChange?: (from?: Date, to?: Date) => void;
}

export const DateRangePicker = (props: DateRangePickerProps) => {
  const [start] = useState(props.start);
  const [months, setMonths] = useState<CalendarMonth[]>([]);
  const [range, setRange] = useState<DateRange>({});

  useEffect(() => {
    const year = start.getFullYear();
    const month = start.getMonth();
    const values = Array.from(
      { length: 4 },
      (_, i) => new Date(year, month + i - 1, 1)
    );
    setMonths(
      values.map((m, i) => ({
        month: m,
        offset: i - 1,
        weeks: generateWeeks(m, props.publicHolidays)
      }))
    );
  }, [start, props.publicHolidays]);

  const handleNext = (adj: number) => {
    setMonths((prev) => {
      if (adj < 0 && prev[0].offset === -1) {
        const m = prev[0].month.previousMonth();
        prev = [
          {
            offset: -2,
            month: m,
            weeks: generateWeeks(m, props.publicHolidays)
          },
          ...prev
        ];
      } else if (adj > 0 && prev[prev.length - 1].offset === 2) {
        const m = prev[prev.length - 1].month.nextMonth();
        prev = [
          ...prev,
          { offset: 3, month: m, weeks: generateWeeks(m, props.publicHolidays) }
        ];
      }
      return prev.map((m) => ({ ...m, offset: m.offset - adj }));
    });
  };

  const handleDayClicked = (date: Date | undefined) => {
    const findAndApply = (
      date: Date,
      prev: DateRange,
      months: CalendarMonth[]
    ) => {
      if (prev.to === date) {
        setDaySelected(findDay(months, date), false);
        return { from: prev.from, to: undefined };
      }
      if (prev.from === date) {
        setDaySelected(findDay(months, date), false);
        return { from: prev.to, to: undefined };
      }
      if (!prev.from) {
        setDaySelected(findDay(months, date), true);
        return { from: date, to: undefined };
      }
      if (!prev.to) {
        setDaySelected(findDay(months, date), true);
        if (prev.from < date) {
          return { from: prev.from, to: date };
        } else {
          return { from: date, to: prev.from };
        }
      }

      if (date < prev.from) {
        setDaySelected(findDay(months, prev.from), false);
        setDaySelected(findDay(months, date), true);
        return { from: date, to: prev.to };
      } else {
        setDaySelected(findDay(months, prev.to), false);
        setDaySelected(findDay(months, date), true);

        return { from: prev.from, to: date };
      }
    };

    setRange((prev) => {
      if (!date) return prev;
      const adj = findAndApply(date, prev, months);
      setTimeout(() => {
        props.onChange?.(adj.from, adj.to);
      }, 10);
      return adj;
    });
  };

  return props.stacked ? (
    <div
      className="relative h-[30rem] overflow-clip"
      style={{ containerType: 'size' }}
    >
      <div className="absolute inset-x-1 top-1.5 z-10">
        <IconButton left onClick={handleNext.bind(this, -1)}>
          chevron_left
        </IconButton>
        <IconButton right onClick={handleNext.bind(this, 1)}>
          chevron_right
        </IconButton>
      </div>
      {months.map((month, i) => (
        <div
          key={`month-${month.month.toISOString()}`}
          className="transition[top] absolute inset-0 h-[15rem] duration-300 ease-in-out"
          style={{ top: `${month.offset * 50}cqh` }}
        >
          <div className="mb-2 leading-10">
            <div className="text-center">
              {month.month.toLocaleString('de-DE', {
                month: 'long',
                year: 'numeric'
              })}
            </div>
          </div>
          <DateCalendar
            weeks={month.weeks}
            onClicked={(e, d) => handleDayClicked(d)}
            highlight={range}
          />
        </div>
      ))}
    </div>
  ) : (
    <div
      className="relative overflow-clip text-left sm:h-60 sm:text-nowrap"
      style={{ containerType: 'inline-size' }}
    >
      <div className="absolute inset-x-1 top-1.5 z-10">
        <IconButton left onClick={handleNext.bind(this, -1)}>
          chevron_left
        </IconButton>
        <IconButton right onClick={handleNext.bind(this, 1)}>
          chevron_right
        </IconButton>
      </div>
      {months.map((month, i) => (
        <div
          key={`month-${month.month.toISOString()}`}
          className="transistion-[left] inline-block w-full px-2 duration-300 ease-in-out sm:absolute sm:w-[50cqw]"
          style={{ left: `${month.offset * 50}cqw` }}
        >
          <div className="mb-2 leading-10">
            <div className="text-center">
              {month.month.toLocaleString('de-DE', {
                month: 'long',
                year: 'numeric'
              })}
            </div>
          </div>
          <DateCalendar
            weeks={month.weeks}
            onClicked={(e, d) => handleDayClicked(d)}
            highlight={range}
          />
        </div>
      ))}
    </div>
  );
};
