import { IMemberAssigments } from '@kidsmanager/util-models';
import { IAuth, fetch } from '../auth';
import { BackendCache } from '../backend-cache';

const cache = new BackendCache();

export class ApiRosterPlan {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {}

  async find(
    year: number,
    month: number,
    members: string[]
  ): Promise<IMemberAssigments[]> {
    if (!members.length) {
      return [];
    }

    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    return cache.get<IMemberAssigments[]>(
      `plan-${iso_month}-${members.join('-')}`,
      async () => {
        return (
          await this.fetch(
            `api/roster/plan/latest?month=${iso_month}${members.map((member) => `&id=${member}`).join('')}`
          )
        ).json();
      }
    );
  }
}
