import { ClientBackendContext } from '@kidsmanager/ui-api';
import { Button } from '@kidsmanager/ui-core';
import { IHolidayBooking } from '@kidsmanager/util-models';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const HolidayViewDay = () => {
  const [holiday, setHoliday] = useState<IHolidayBooking>();
  const [days, setDays] = useState(0);

  const params = useParams<{ start: string }>();
  const client = useContext(ClientBackendContext);
  const navigate = useNavigate();

  useEffect(() => {
    const start = new Date(params.start || Date.now());
    const year = start.getFullYear();

    Promise.all([
      client.holiday.booking(start),
      client.holiday.publicHolidays(year - 1, year, year + 1)
    ]).then(([booking, publicHolidays]) => {
      setHoliday(booking);
      setDays(
        () => booking?.from.workingDaysUntil(booking.to, publicHolidays) || 0
      );
    });
  }, [client, params.start]);

  const formatDate = (date: Date) =>
    date.toLocaleString('de-DE', {
      weekday: 'short',
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });

  const shortDate = (date: string | null) =>
    new Date(date || 0).toLocaleString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });

  const handleCancelRequest = async () => {
    if (!holiday) {
      return;
    }
    await client.holiday.cancelRequest(holiday.from);
    navigate(-1);
  };

  return !holiday ? undefined : (
    <div>
      <table className="mx-auto mt-8 w-full max-w-md">
        <tbody>
          <tr>
            <td className="pb-4 font-medium">Von</td>
            <td className="pb-4 text-right">{formatDate(holiday.from)}</td>
          </tr>
          <tr>
            <td className="pb-4 font-medium">Bis</td>
            <td className="pb-4 text-right">{formatDate(holiday.to)}</td>
          </tr>
          <tr>
            <td className="pb-4 font-medium">Urlaubstage</td>
            <td className="pb-4 text-right">{days}</td>
          </tr>
          <tr>
            <td className="pb-4 font-medium">Status</td>
            <td className="pb-4 text-right">
              {holiday.approvedWhen ? (
                <span>
                  Genehmigt
                  <br />
                  {`${shortDate(holiday.approvedWhen)}; ${holiday.approvedBy}`}
                </span>
              ) : (
                <span>Best&auml;tigung ausstehend</span>
              )}
            </td>
          </tr>
        </tbody>
      </table>
      {!holiday.approvedWhen && (
        <div className="mt-10 text-center">
          <Button
            className="uppercase"
            onClick={handleCancelRequest.bind(this)}
          >
            Antrag stornieren
          </Button>
        </div>
      )}
    </div>
  );
};
