import { IUser } from '@kidsmanager/util-models';

export interface UserListProps {
  users: IUser[];
  onClick?: (user: IUser) => void;
  focus?: boolean;
}

export const UserList = (props: UserListProps) => {
  const hasFocus = props.focus ?? true;
  return (
    <ul>
      {props.users.map((user) => (
        <div
          onClick={() => props.onClick?.(user)}
          className="outline-focus mx-1 my-1 cursor-pointer rounded py-1 pl-4 hover:bg-neutral-100"
          onKeyUp={(e) => e.key === 'Enter' && props.onClick?.(user)}
          key={user.userId}
          tabIndex={hasFocus ? 0 : undefined}
        >
          {user.name}
        </div>
      ))}
    </ul>
  );
};
