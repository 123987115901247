export class ProRataCalculator<T> {
  changes: { from: Date; value: number }[] = [];

  constructor(
    changes: T[],
    valueField: keyof T,
    dateField: keyof T = 'from' as keyof T
  ) {
    if (!changes) {
      return;
    }
    this.changes = changes.map((x) => ({
      from: new Date(x[dateField] as string),
      value: x[valueField] as number
    }));
    this.changes.sort((a, b) => (a.from > b.from ? 1 : -1));
  }

  forYear(year: number): number {
    if (this.changes.length === 0) {
      return 0.0;
    }

    let value = 0.0;
    const start = new Date(year, 0, 1);
    const end = new Date(year + 1, 0, 1);
    const daysOfYear =
      (end.getTime() - start.getTime()) / (24 * 60 * 60 * 1000);

    const index = (() => {
      for (let i = this.changes.length - 1; i >= 0; i--) {
        if (this.changes[i].from < end) {
          return i;
        }
      }
      return -1; // If no match is found, return -1
    })();

    for (let i = index; i >= 0; i--) {
      const from = Math.max(start.getTime(), this.changes[i].from.getTime());
      const to = i < index ? this.changes[i + 1].from.getTime() : end.getTime();
      const diff = (to - from) / (24 * 60 * 60 * 1000);
      value += this.changes[i].value * (diff / daysOfYear);

      //break when we finished with all current allowances
      if (i > 0 && this.changes[i].from <= start) {
        break;
      }
    }

    return Math.round(value);
  }
}
