import { useNavigate, useParams } from 'react-router-dom';
import { MonthCalendarView } from './month-calendar-view';
import { MonthSummary } from './month-summary';
import { MonthDayDetails } from './month-day-details';
import { TimesheetLowerHeader } from '../timesheet-common/timesheet-lower-header';
import { TimesheetStats } from '../timesheet-common/timesheet-stats';
import { useContext, useEffect, useState } from 'react';
import { DropdownMenu } from '@kidsmanager/ui-core';
import { ClientBackendContext } from '@kidsmanager/ui-api';
import { IHoursForMonth } from 'libs/ui-api/src/lib/timesheet/api-timesheet-helper';

const workingDays = (date: Date, publicHols: Date[]): number => {
  const days = Array.from({
    length: new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
  })
    .map((_, i) => new Date(date.getFullYear(), date.getMonth(), i + 1))
    .filter((day) => {
      if (day.isWeekend()) {
        return false;
      }
      if (publicHols.find((h) => h.dateMatches(day))) {
        return false;
      }
      return true;
    });
  return days.length;
};

export const TimesheetViewMonth = () => {
  const [actual, setActual] = useState(0);
  const [contracted, setContracted] = useState<IHoursForMonth>();
  const [selected, setSelected] = useState<Date>();
  const [setPublicHols] = useState<Date[]>([]);
  const [date, setDate] = useState<Date>(new Date());
  const [days, setDays] = useState<(null | string | number)[]>([]);
  const [workDays, setWorkDays] = useState(0);

  const params = useParams();
  const client = useContext(ClientBackendContext);
  const navigate = useNavigate();

  useEffect(() => {
    const date = new Date(params.date || Date.now());
    setDate(date);
    Promise.all([
      client.timesheet.month(date.getFullYear(), date.getMonth() + 1),
      client.timesheet.contracted(date),
      client.holiday.publicHolidays(date.getFullYear()),
      client.holiday.bookingsForYear(date.getFullYear())
    ]).then(([month, contracted, publicHols, holidays]) => {
      setContracted(contracted);
      setActual(month.daysTotal);
      const days: (null | string | number)[] = Array.from(
        { length: month.days.length },
        (_, i) => {
          const d = new Date(
            Date.UTC(date.getFullYear(), date.getMonth(), i + 1)
          );
          if (d.isWeekend()) {
            return month.days[i];
          }
          if (publicHols.find((h) => h.dateMatches(d))) {
            return 'FT';
          }
          if (holidays.find((h) => d >= h.from && d <= h.to)) {
            return 'UT';
          }
          return month.days[i];
        }
      );
      setDays(days);
      setWorkDays(workingDays(date, publicHols));
    });
  }, [params.date, client]);

  const onClicked = (date?: Date) => {
    setSelected((prev) => {
      if (!date) return undefined;
      return prev?.getTime() === date.getTime() ? undefined : date;
    });
  };

  const onMove = (delta: number) => {
    const d = new Date(
      date.getFullYear(),
      date.getMonth() + delta,
      date.getDate()
    );
    navigate(`/work/${d.toLocaleISODate()}/month`);
  };

  return (
    <>
      <TimesheetLowerHeader>
        <div className="flex items-center px-4 text-center text-2xl sm:block sm:text-3xl">
          <span
            className="material-icons cursor-pointer hover:text-black/50"
            style={{ userSelect: 'none' }}
            onClick={onMove.bind(this, -1)}
          >
            arrow_back_ios
          </span>
          <span className="md:mx-2">
            {date
              .toLocaleString('de-DE', { month: 'short', year: 'numeric' })
              .replace('.', '')}
          </span>
          <span
            className="material-icons cursor-pointer hover:text-black/50"
            style={{ userSelect: 'none' }}
            onClick={onMove.bind(this, 1)}
          >
            arrow_forward_ios
          </span>
        </div>
        <TimesheetStats
          actual={actual}
          planned={workDays * (contracted?.hoursPerDay || 0)}
        />
      </TimesheetLowerHeader>
      <div className="p-2">
        <div className="pb-2 text-right text-xs">
          <DropdownMenu
            options={['Eingetragene', 'Geplante', 'Eingetragene & Geplante']}
          />
        </div>
        <MonthCalendarView
          date={date}
          days={days}
          onClick={onClicked.bind(this)}
          selected={selected}
        />
      </div>
      <div className="flex justify-center py-10">
        <div className="max-w-lg flex-1">
          {selected && <MonthDayDetails date={selected} />}
          {!selected && (
            <MonthSummary
              date={date}
              workDays={workDays}
              hoursPerWeek={contracted?.hoursPerDay || 0}
              actual={actual}
              planned={workDays * (contracted?.hoursPerDay || 0)}
            />
          )}
        </div>
      </div>
    </>
  );
};
