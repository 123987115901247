import { IShiftSpec } from '@kidsmanager/util-models';
import { IAuth, fetch } from '../auth';
import { BackendCache } from '../backend-cache';

const cache = new BackendCache();

export class ApiRosterShifts {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {}

  async list(
    groupId: string,
    year: number,
    month: number
  ): Promise<IShiftSpec[]> {
    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    return cache.get(`shifts-${groupId}-${iso_month}`, async () => {
      return (
        await this.fetch(`api/roster/${groupId}/shifts?month=${iso_month}`)
      ).json();
    });
  }

  async save(
    groupId: string,
    year: number,
    month: number,
    shifts: IShiftSpec[]
  ): Promise<IShiftSpec[]> {
    const projected = shifts.map((shift) => ({
      id: shift.id,
      index: shift.index,
      color: shift.color,
      created: shift.created,
      closed: shift.closed,
      name: shift.name,
      start: shift.start,
      end: shift.end,
      hrs: shift.hrs
    }));

    const url = `api/roster/${groupId}/shifts?month=${new Date(year, month - 1).toLocaleISOMonth()}`;
    const body = JSON.stringify(projected);
    const response = await this.fetch(url, {
      method: 'PUT',
      body
    });
    cache.clear(
      `shifts-${groupId}-${new Date(year, month - 1).toLocaleISOMonth()}`
    );
    return response.json();
  }
}
