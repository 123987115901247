import { ClientBackendContext } from '@kidsmanager/ui-api';
import { IEntry, emptyEntry } from '@kidsmanager/util-models';
import { useContext, useEffect, useState } from 'react';
import {
  formatBreak,
  formatEnd,
  formatStart
} from '../timesheet-common/timesheet-formatters';

export interface MonthDayDetailsProps {
  date?: Date;
}

export const MonthDayDetails = (props: MonthDayDetailsProps) => {
  const client = useContext(ClientBackendContext);
  const [entries, setEntries] = useState<IEntry[]>([]);
  const [worked, setWorked] = useState(0);

  useEffect(() => {
    const date = props.date;
    if (!date) {
      return;
    }
    Promise.all([
      client.timesheet.week(date),
      client.timesheet.contracted(date),
      client.holiday.bookingsForYear(date.getFullYear()),
      client.holiday.publicHolidays(date.getFullYear())
    ]).then(([week, contracted, holidays, publicHols]) => {
      const match = week.current.find(({ date }) =>
        props.date?.dateMatches(new Date(date))
      );
      if (!match) {
        setEntries([]);
        setWorked(0);
        return;
      }
      const entries = [...match.entries];
      const date = new Date(match.date);
      const booking = holidays.find((h) => date >= h.from && date <= h.to);

      if (booking && !publicHols.find((x) => x.dateMatches(date))) {
        entries.push({
          ...emptyEntry,
          label: 'Urlaub',
          date: date.toLocaleISODate(),
          startSeconds: 8 * 3600 + date.getTimezoneOffset() * 60,
          durationSeconds: contracted.hoursPerDay * 3600
        });
      }

      setEntries(entries);

      setWorked(
        entries
          .filter((x) => !x.infoOnly)
          .reduce(
            (sum, x) => sum + x.durationSeconds - x.breakDurationSeconds,
            0
          ) / 3600
      );
    });
  }, [client, props.date]);

  return !props.date ? undefined : (
    <div>
      <h2 className="mb-2 flex items-center font-medium">
        <span className="flex-1">
          {props.date.toLocaleString('de-DE', {
            weekday: 'long',
            day: 'numeric',
            month: 'short'
          })}
        </span>
        <span className="text-sm text-black/50">{worked.toFixed(2)}h</span>
      </h2>

      {entries.map((entry, index) => (
        <div className="flex text-black/70" key={index}>
          <div className="basis-32">
            {formatStart(entry)} - {formatEnd(entry)}
          </div>
          <div className="flex-1">
            <div className="flex items-center">
              {entry.label}
              {entry.tag && (
                <span className="ml-1 rounded-md bg-orange-500 px-1 py-0.5 text-xs text-white">
                  {entry.tag}
                </span>
              )}
            </div>
            {entry.note && (
              <div className="pl-2 text-sm italic text-black/60">
                &quot;{entry.note}
              </div>
            )}
          </div>
          <div className="basis-32 text-right">{formatBreak(entry)}</div>
        </div>
      ))}
    </div>
  );
};
