import React from 'react';

export interface DropdownMenuProps {
  selected?: string;
  options: string[];
  onChange?: (value: string) => void;
}

export const DropdownMenu = (props: DropdownMenuProps) => {
  const [selected, setSelected] = React.useState(
    props.selected || props.options[0]
  );

  React.useEffect(() => {
    setSelected(props.selected || props.options[0]);
  }, [props.selected, props.options]);

  const onSelected = (option: string) => {
    if (props.onChange) {
      props.onChange(option);
    } else {
      setSelected(option);
    }
    (document.activeElement as HTMLElement).blur();
  };

  return (
    <div className="">
      <div
        tabIndex={0}
        className="outline-focus group relative inline-flex cursor-pointer items-center px-1"
      >
        <span className="text-xs text-black/60">{selected}</span>
        <span className="material-icons text-xs text-black/60">
          arrow_drop_down
        </span>
        <ul className="shadow-hdr border-silver-300 absolute right-2 top-6 z-50 hidden border bg-white py-2 text-left group-focus-within:block">
          {props.options.map((option) => (
            <li
              key={option}
              className={`text-hdr min-w-14 text-nowrap px-4 py-2 hover:bg-stone-200 ${selected === option ? 'font-bold' : ''}`}
              onClick={onSelected.bind(this, option)}
            >
              {option}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
