import { Input } from '../input/input';
import { ExpansionPanel } from '../expansion-panel/expansion-panel';
import { useEffect, useRef, useState } from 'react';
import { UserList } from './user-list';
import { PopupButton } from '../buttons';
import { IUserGroup, IUser } from '@kidsmanager/util-models';

export interface UserPickerProps {
  selected?: IUser;
  groups: IUserGroup[];
  onSelected?: (user: IUser) => void;
}

export const UserPicker = (props: UserPickerProps) => {
  const [expanded, setExpanded] = useState<string | null>(null);
  const [pattern, setPattern] = useState<string>('');
  const [filtered, setFiltered] = useState<IUser[]>([]);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!props.selected) {
      return;
    }
    const match = props.groups.find((group) =>
      group.users.find((user) => user.userId === props.selected?.userId)
    );
    setExpanded(match?.name || null);
  }, [props.groups, props.selected]);

  const onFilter = (value: string) => {
    setPattern(value);
    if (!value) {
      setFiltered([]);
      return;
    }
    setFiltered(() => {
      const users = props.groups.flatMap((group) =>
        group.users.filter((user) =>
          user.name.toLowerCase().includes(value.toLowerCase())
        )
      );
      users.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      );
      return users;
    });
  };

  const onUserSelect = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      if (pattern.length > 0) {
        setPattern('');
        setFiltered([]);
      } else {
        (document.activeElement as HTMLElement).blur();
      }
    }
    if (e.key === 'Enter' && filtered.length === 1) {
      props.onSelected?.(filtered[0]);
      setPattern('');
      setFiltered([]);
    }
  };

  const onToggleGroup = (id: string) => {
    setExpanded((prev) => (prev === id ? null : id));
  };

  const onUserSelectStart = () => {
    if (!ref.current) return;
    ref.current.getElementsByTagName('input')[0].focus();
  };

  return (
    <PopupButton
      label={props.selected?.name || ''}
      onClick={onUserSelectStart.bind(this)}
    >
      <div
        className="max-h-96 max-w-md overflow-scroll text-base"
        onKeyDown={onUserSelect.bind(this)}
      >
        <div className="p-1" ref={ref}>
          <Input
            placeholder="Mitarbeiter filtern..."
            prefix={<span className="material-icons">search</span>}
            onChange={onFilter.bind(this)}
            value={pattern}
          />
        </div>
        {pattern.length > 0 && (
          <div>
            {filtered.length === 0 && (
              <div className="p-2 text-center text-sm">
                Kein passender Mitarbeiter gefunden
              </div>
            )}
            <UserList users={filtered} onClick={props.onSelected} />
          </div>
        )}
        {pattern.length === 0 && props.groups.length === 1 && (
          <UserList users={props.groups[0].users} onClick={props.onSelected} />
        )}
        {pattern.length === 0 && props.groups.length > 1 && (
          <div className="flex min-h-40 flex-col gap-2 rounded bg-neutral-100 p-2">
            {props.groups.map((group, index) => (
              <ExpansionPanel
                id={group.name}
                label={group.name}
                key={`group-${index}`}
                expanded={expanded === group.name}
                onToggle={onToggleGroup.bind(this)}
              >
                <UserList
                  focus={expanded === group.name}
                  users={group.users}
                  onClick={props.onSelected}
                />
              </ExpansionPanel>
            ))}
          </div>
        )}
      </div>
    </PopupButton>
  );
};
