export interface TimesheetStatsProps {
  actual: number;
  planned: number;
}

export const TimesheetStats = ({ actual, planned }: TimesheetStatsProps) => {
  return (
    <div>
      <div className="text-center text-3xl">{actual.toFixed(1)}</div>
      <div className="flex items-center justify-center gap-1 text-sm">
        <span>SOLL {planned.toFixed(1)}h</span>
        {actual !== planned && (
          <>
            <span className="material-icons" style={{ fontSize: '16px' }}>
              {actual <= planned ? 'arrow_downward' : 'arrow_upward'}
            </span>
            <span>{(planned - actual).toFixed(1)}</span>
          </>
        )}
      </div>
    </div>
  );
};
