import { SplitButton, ToggleButton } from '@kidsmanager/ui-core';
import { IShift } from '@kidsmanager/util-models';
import { useEffect, useState } from 'react';

export interface ShiftSelectorProps {
  shifts: IShift[];
  selected: IShift;
  onChange?: (shift: IShift) => void;
}

export const ShiftSelector = (props: ShiftSelectorProps) => {
  const [options, setOptions] = useState<IShift[]>([]);
  const [selectedOption, setSelectedOption] = useState<IShift>();

  useEffect(() => {
    if (props.shifts.length < 2) {
      setOptions([]);
    } else {
      setSelectedOption(props.shifts[1]);
      setOptions(props.shifts.slice(1));
    }
  }, [props.shifts]);

  const handleSelect = (option: IShift | string) => {
    if (typeof option === 'string') {
      const found = options.find((x) => x.name === option);
      if (!found) {
        return;
      }
      setSelectedOption(found);
      props.onChange?.(found);
    } else {
      props.onChange?.(option);
    }
  };

  return props.shifts.length < 2 ? undefined : (
    <div className="grid grid-cols-2 gap-4 md:gap-10 md:px-10">
      <ToggleButton
        color="primary"
        active={props.selected.id === props.shifts[0].id}
        onChange={() => handleSelect(props.shifts[0])}
      >
        <div className="py-1 uppercase tracking-wide md:px-2">
          {props.shifts[0].name}
        </div>
      </ToggleButton>
      {options.length === 1 && (
        <ToggleButton
          color="primary"
          active={props.selected.id === props.shifts[1].id}
          onChange={() => handleSelect(selectedOption || options[0])}
        >
          <div className="py-1 uppercase tracking-wide md:px-2">
            {options[0].name}
          </div>
        </ToggleButton>
      )}
      {options.length > 1 && (
        <SplitButton
          options={options.map((x) => x.name)}
          selected={props.selected.name}
          onSelect={handleSelect.bind(this)}
        >
          <div className="py-1 uppercase tracking-wide md:px-2">
            {selectedOption?.name}
          </div>
        </SplitButton>
      )}
    </div>
  );
};
