import { FormEvent, useContext, useEffect, useState } from 'react';
import { ClientBackendContext } from '@kidsmanager/ui-api';
import { useSearchParams } from 'react-router-dom';
import { Button, Input, Logo, Progress } from '@kidsmanager/ui-core';
import { UserNotFoundException } from '@kidsmanager/util-common';

export const FeatureGoogleCallback = () => {
  const client = useContext(ClientBackendContext);
  const [searchParams] = useSearchParams();
  const [tenant, setTenant] = useState<string | null>(null);
  const [needsLinking, setNeedsLinking] = useState(false);
  const [googleEmail, setGoogleEmail] = useState('');
  const [usernameWithoutTenant, setusernameWithoutTenant] = useState('');
  const [password, setPassword] = useState('');
  const [withConfirm, setWithConfirm] = useState(
    localStorage.getItem('debug-auth') === 'true'
  );

  useEffect(() => {
    if (!tenant || needsLinking) {
      return;
    }
    if (!withConfirm) {
      window.opener.postMessage({
        success: true,
        tenant,
        token: client.auth.token(),
        refreshToken: client.auth.refreshToken()
      });
      window.close();
    }
  }, [tenant, needsLinking, withConfirm, client]);

  const linkLegacyAccount = async (event: FormEvent) => {
    event.preventDefault();
    const user = `${tenant}\\${usernameWithoutTenant}`;

    const state = await client.auth.authenticateUsernamePassword(
      user,
      password
    );
    if (state === 'Authenticated') {
      await client.apiOauth2.setEmailAddress(googleEmail);
    }
    setNeedsLinking(state !== 'Authenticated');
    // TODO error handling for unknown user
  };

  useEffect(() => {
    document.title = 'KidsManager | Google Login';
    const code = searchParams.get('code');
    if (code) {
      client.apiOauth2
        .verify(code)
        .then((response) => {
          if (response) {
            client.auth.processAuthResponse(response, false);
            setTenant(sessionStorage.getItem('tenant'));
          }
        })
        .catch((error) => {
          if (error instanceof UserNotFoundException) {
            setTenant(error.tenant);
            setGoogleEmail(error.email);
          }
          setNeedsLinking(true);
        });
    }
  }, [searchParams, client]);

  const invalidLogindata = () => {
    return !usernameWithoutTenant || !password;
  };

  const handleClose = () => {
    if (!tenant) {
      return;
    }
    setWithConfirm(false);
  };

  return (
    <div className="relative pt-52 text-center">
      <div
        className={`absolute left-1/2 -translate-x-1/2 transition-all duration-500 ease-out ${needsLinking ? 'top-16 scale-75' : 'top-28'}`}
      >
        <Logo />
      </div>

      {!needsLinking && (
        <div className="mx-auto mt-20 max-w-sm">
          <Progress mode="indeterminate" />
          <p className="font-sm pt-1 text-black/50">Konto wird überprüft...</p>
        </div>
      )}
      {needsLinking && (
        <div className="mx-auto max-w-sm">
          <p>Mit bestehendem Konto verbinden</p>
          <form
            className="flex flex-col gap-2 pt-4"
            onSubmit={linkLegacyAccount.bind(this)}
          >
            <Input
              prefix={`${tenant}\\`}
              placeholder="Benutzername"
              value={usernameWithoutTenant}
              onChange={setusernameWithoutTenant.bind(this)}
            />
            <Input
              placeholder="Passwort"
              type="password"
              value={password}
              onChange={setPassword.bind(this)}
            />
            <div className="text-end">
              <Button disabled={invalidLogindata()}>Verbinden</Button>
            </div>
          </form>
        </div>
      )}

      {withConfirm && (
        <div className="fixed bottom-0 right-0 p-6">
          <Button onClick={handleClose.bind(this)}>Fortsetzen</Button>
        </div>
      )}
    </div>
  );
};
