export interface ISubShift {
  start: number;
  end: number;
  fixed: boolean;
}

export interface ISaveAs {
  name: string;
  description: string;
  abbr: string;
  infoOnly: boolean;
}

export interface INonWork {
  name: string;
  abbr: string;
  code: string;
  infoOnly: boolean;
}

export interface IShift {
  id: string;
  name: string;
  nominalStartHours: number;
  nominalLengthHours: number;
  nominalBreakHours: number;
  shiftPlan: boolean;
  fromTracking: boolean;
  costCenter: string;
  nightshift: ISubShift;
  onCall: ISubShift;
  saveAs: ISaveAs[];
  nonWork: INonWork[];
}

export interface IUserSummary {
  name: string;
  employeeNumber: string;
  monthlyAverage: number;
  weeklyAverage: number;
}

export interface ISaveEntry {
  shiftId: string;
  duration: number;
  breakDuration: number;
  index: number;
  total: number;
  label: string;
  note: string;
  tag: string;
  type: string;
  code: string;
  infoOnly: boolean;
}

export interface IEntry {
  shiftId: string;
  date: string;
  startSeconds: number;
  durationSeconds: number;
  breakDurationSeconds: number;
  infoOnly: boolean;
  fromTracking: boolean;
  index: number;
  total: number;
  label: string;
  note: string;
  tag: string;
  type: string;
  code: string;
}

export const emptyEntry: IEntry = {
  shiftId: '',
  date: '',
  startSeconds: 0,
  durationSeconds: 0,
  breakDurationSeconds: 0,
  infoOnly: false,
  fromTracking: false,
  index: 0,
  total: 0,
  label: '',
  note: '',
  tag: '',
  type: '',
  code: ''
};

export interface IDay {
  date: string;
  entries: IEntry[];
}

export interface IWeek {
  week: string;
  worked: number;
  current: IDay[];
  previous: IDay[];
  next: IDay[];
}

export interface IMonth {
  days: (string | number | null)[];
  planned: number[];
  worked: number[];
  adjustments: number[];
  daysTotal: number;
}

export interface IValueFrom {
  from: string;
  hours: number;
}

export interface IMonthSummary {
  year: number;
  month: number;

  planned: number;
  worked: number;
  adjustment: number;

  employeeApproved: Date | null;
  employeeName: string | null;
  managerApproved: Date | null;
  managerName: string | null;
}
