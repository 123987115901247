import { ClientBackendContext } from '@kidsmanager/ui-api';
import { DrawerContext } from '@kidsmanager/ui-core';
import { emptyEntry } from '@kidsmanager/util-models';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EditableEntry, IEditableEntry } from './editable-entry';
import {
  extractWorkAndNonWorkTypes,
  EntryOptions
} from './work-nonwork-extractor';

export const TimesheetViewWeekDay = () => {
  const params = useParams<{ date: string; day: string }>();

  const drawer = useContext(DrawerContext);
  const client = useContext(ClientBackendContext);
  const [entries, setEntries] = useState<IEditableEntry[]>([]);
  const [options, setOptions] = useState<EntryOptions>();

  useEffect(() => {
    const day = new Date(params.day || Date.now());
    drawer.configure(
      day.toLocaleString('de-DE', {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      }),
      null,
      `/work/${params.date}/week`
    );
  }, [drawer, params.date, params.day]);

  useEffect(() => {
    const day = new Date(params.day || Date.now());
    Promise.all([
      client.timesheet.shifts(),
      client.timesheet.week(day),
      client.timesheet.contracted(day),
      client.holiday.bookingsForYear(day.getFullYear()),
      client.holiday.publicHolidays(day.getFullYear())
    ]).then(([shifts, week, contracted, holidays, publicHols]) => {
      const match = week.current.find(({ date }) =>
        day.dateMatches(new Date(date))
      );
      if (!match) {
        setEntries([]);
        setOptions(extractWorkAndNonWorkTypes(shifts));
        return;
      }
      const entries = [...match.entries] as IEditableEntry[];
      const date = new Date(match.date);
      const booking = holidays.find((h) => date >= h.from && date <= h.to);
      if (booking && !publicHols.find((x) => x.dateMatches(date))) {
        entries.push({
          ...emptyEntry,
          label: 'Urlaub',
          date: date.toLocaleISODate(),
          startSeconds: 8 * 3600 + date.getTimezoneOffset() * 60,
          durationSeconds: contracted.hoursPerDay * 3600,
          state: 'readonly'
        });
      }
      setEntries(entries);
      setOptions(extractWorkAndNonWorkTypes(shifts));
    });
  }, [client, params.day]);

  const handleAdd = () => {
    if (!params.date) {
      return;
    }
    const start = new Date();
    const newEntry = { ...emptyEntry } as IEditableEntry;
    newEntry.state = 'new';
    newEntry.date = params.date;
    newEntry.durationSeconds = 4 * 3600;
    newEntry.startSeconds =
      start.getHours() * 3600 + start.getTimezoneOffset() * 60;
    setEntries((prev) => [...prev, newEntry]);
  };

  const handleDelete = async (entry: IEditableEntry) => {
    if (entry.state !== 'new') {
      await client.timesheet.log.delete(entry.date, entry.startSeconds);
    }
    setEntries((prev) => prev.filter((x) => x !== entry));
  };

  const handleSave = async (
    source: IEditableEntry,
    updated: IEditableEntry
  ) => {
    if (source.state !== 'new') {
      await client.timesheet.log.delete(source.date, source.startSeconds);
    }
    await client.timesheet.log.update(updated);
    updated.state = undefined;
    setEntries((prev) => prev.map((x) => (x === source ? updated : x)));
  };

  return (
    <div className="px-1 py-2">
      <ul>
        {entries.map((entry, index) => (
          <EditableEntry
            key={index}
            entry={entry}
            options={options}
            delete={handleDelete.bind(this)}
            save={handleSave.bind(this)}
          />
        ))}
      </ul>
      <div>
        <span
          className="cursor-pointer px-4 text-sm underline underline-offset-4"
          onClick={handleAdd.bind(this)}
        >
          + hinzufügen
        </span>
      </div>
    </div>
  );
};
