import { useContext, useEffect, useState } from 'react';
import { DrawerContext, DrawerTabs } from '@kidsmanager/ui-core';
import { Outlet, useParams } from 'react-router-dom';
import { ClientBackendContext } from '@kidsmanager/ui-api';
import {
  availableInYear,
  holidaysInYear
} from '../holiday-common/holiday-helpers';

export const HolidayManager = () => {
  const drawer = useContext(DrawerContext);
  const client = useContext(ClientBackendContext);
  const params = useParams<{ start?: string }>();

  const [availableLabel, setAvailableLabel] = useState('');

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    Promise.all([
      client.holiday.bookings(),
      client.holiday.allowances(),
      client.holiday.publicHolidays(year - 1, year, year + 1)
    ]).then(([bookings, allowances, publicHolidays]) => {
      const soFar = holidaysInYear(year, bookings, publicHolidays);
      const yearTotal = availableInYear(year, allowances);
      setAvailableLabel(`${yearTotal - soFar} von ${yearTotal} verbleibend`);
    });
  }, [client]);

  useEffect(() => {
    if (params.start) {
      const start = new Date(params.start || Date.now());
      const from = `von ${start.toLocaleString('de-DE', {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      })}`;
      drawer.configure(['Urlaub', 'Richard Reynolds', from], null, '..');
    } else {
      const tabs = [
        { label: 'Aktuell', path: '/holiday', root: true },
        { label: 'Verlauf', path: '/holiday/history' }
      ];
      drawer.configure(['Urlaub', availableLabel], <DrawerTabs tabs={tabs} />);
    }
  }, [drawer, availableLabel, params]);

  return <Outlet />;
};
