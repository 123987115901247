import { ClientBackendContext } from '@kidsmanager/ui-api';
import { useContext, useEffect } from 'react';

export const FeatureLogout = () => {
  const client = useContext(ClientBackendContext);
  useEffect(() => {
    const tenant = 'demo'; //TODO: get tenant from client

    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.onload = () => {
      client.auth.logout();
      window.location.href = '/';
    };
    iframe.src = `https://${tenant}.kidsmanager.at/account/unlink`;
    document.body.appendChild(iframe);
  }, [client]);
  return <div></div>;
};
