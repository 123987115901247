import { DateSlider, Textarea } from '@kidsmanager/ui-core';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ShiftSelector } from './shift-selector';
import { NightDutyLogger } from './night-duty-logger';
import { ShiftSaveAs } from './shift-save-as';
import { YesterdayToggle } from './yesterday-toggle';
import { PlannedShift } from './planned-shift';
import { ClientBackendContext } from '@kidsmanager/ui-api';
import { ISaveAs, ISaveEntry, IShift } from '@kidsmanager/util-models';
import { BreakDeclaration } from './break-declaration';
import { compileNightDutyToSubShifts } from '../timesheet-expander/nightduty-compiler';
import { expandShift } from '../timesheet-expander/shift-expander';

const ticksPerHour = 3600000;
const adjustableMins = 60 * 4;

const toHoursAndMins = (totalHours: number) => {
  const hours = Math.floor(totalHours);
  const mins = (totalHours * 60) % 60;
  return { hours, mins };
};

export const TimesheetViewDay = () => {
  const params = useParams();
  const [saving, setSaving] = useState(false);
  const [nominalStart, setNominalStart] = useState<Date>();
  const [nominalEnd, setNominalEnd] = useState<Date>();
  const [start, setStart] = useState<Date>();
  const [end, setEnd] = useState<Date>();
  const [yesterday, setYesterday] = useState(false);
  const [shifts, setShifts] = useState<IShift[]>([]);
  const [selected, setSelected] = useState<IShift>();
  const [nightDuty, setNightDuty] = useState<string[]>([]);
  const [notes, setNotes] = useState<string>();
  const client = useContext(ClientBackendContext);

  useEffect(() => {
    client.timesheet.shifts().then((shifts) => {
      setShifts(shifts);
      setSelected(shifts[0]);
    });
  }, [client]);

  useEffect(() => {
    if (!selected) return;
    const date = params.date ? new Date(params.date) : new Date();
    const { hours, mins } = toHoursAndMins(selected.nominalStartHours);
    const start = new Date(
      new Date(date).setHours(hours, mins) - (yesterday ? 24 * ticksPerHour : 0)
    );
    const end = new Date(
      new Date(start.getTime() + selected.nominalLengthHours * ticksPerHour)
    );
    setNominalStart(start);
    setNominalEnd(end);
    setStart(start);
    setEnd(end);
  }, [params, selected, yesterday]);

  const handleShiftChange = (shift: IShift) => {
    setSelected(shift);
  };

  const handleOnSaveAs = async (option?: ISaveAs) => {
    if (!start || !end || !selected) return;

    setSaving(true);
    const infoOnly = option?.infoOnly || false;
    const subshifts = compileNightDutyToSubShifts(selected, nightDuty);
    const parts = expandShift(selected, start, end, subshifts, 0, infoOnly);

    const tosave = parts.map((part, index) => {
      const entry: ISaveEntry = {
        shiftId: selected.id,
        duration: (part.until.getTime() - part.from.getTime()) / 1000,
        breakDuration: part.breakHours * 3600,
        index,
        total: part.total,
        label: part.label,
        note: (index === 0 ? notes : undefined) || '',
        tag: option?.abbr || '',
        type: 'work',
        code: '',
        infoOnly: part.infoOnly
      };
      return client.timesheet.log.create(part.from, entry);
    });

    try {
      await Promise.all(tosave);
      setTimeout(() => setSaving(false), 250);
    } catch (error) {
      console.error(error);
    }
  };

  const show = selected && start && end && nominalStart && nominalEnd;
  return (
    show && (
      <div className="relative flex flex-col gap-6 px-4 py-5">
        <PlannedShift date={start}>
          <ShiftSelector
            selected={selected}
            shifts={shifts}
            onChange={handleShiftChange.bind(this)}
          />
          <YesterdayToggle
            hide={!selected.nightshift}
            date={params.date ? new Date(params.date) : new Date()}
            checked={yesterday}
            onChange={setYesterday}
          />
        </PlannedShift>
        <DateSlider
          nominal={nominalStart}
          value={start}
          plus={adjustableMins}
          minus={adjustableMins}
          color="primary"
          invert={true}
          onChange={setStart}
        />
        <DateSlider
          nominal={nominalEnd}
          value={end}
          plus={adjustableMins}
          minus={adjustableMins}
          color="primary"
          onChange={setEnd}
        />
        <NightDutyLogger
          shift={selected}
          onChange={setNightDuty.bind(this)}
          selected={nightDuty}
        />
        <BreakDeclaration
          selected={selected}
          start={start}
          end={end}
          onChange={console.log}
        />
        <ShiftSaveAs
          saving={saving}
          options={selected.saveAs}
          onSaveAs={handleOnSaveAs.bind(this)}
        />
        <Textarea
          placeholder="Optionale Notizen"
          rows={6}
          onChange={setNotes.bind(this)}
        >
          {notes}
        </Textarea>
      </div>
    )
  );
};
