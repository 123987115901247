import { FormEvent, useContext, useEffect, useState } from 'react';
import { ClientBackendContext } from '@kidsmanager/ui-api';
import {
  Button,
  Checkbox,
  IconGoogle,
  Input,
  LoadSpinner,
  Logo
} from '@kidsmanager/ui-core';
import { useNavigate } from 'react-router-dom';

export interface FeatureLoginProps {
  brand: 'kidsmgr' | 'zeitmgr';
  linkWithLegacy?: boolean;
}

export const FeatureLogin = (props: FeatureLoginProps) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const client = useContext(ClientBackendContext);
  const navigate = useNavigate();

  useEffect(() => {
    const isLoggedInViaGoogleSso =
      localStorage.getItem('idp') === 'google' &&
      !!localStorage.getItem('email');
    const isRememberMe = !!localStorage.getItem('refreshToken');
    if (isLoggedInViaGoogleSso) {
      handleGoogleLogin(localStorage.getItem('email'));
    } else if (isRememberMe) {
      navigate('/');
    }
  });

  const onUsernameChange = (value: string) => {
    const fixedUsername = value.replace(/[/]/g, '\\');
    setUsername(fixedUsername);
  };

  const invalidLogindata = () => {
    const validUsernameRegex = /^[a-zA-Z]{2,}\\.{2,}$/;
    return !username || !password || !validUsernameRegex.test(username);
  };

  const addIFrame = (otp: string, tenant: string) => {
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.onload = () => {
      setTimeout(() => {
        handleLinkMessage({ data: { tenant } });
      }, 400);
    };
    iframe.src = `https://${tenant}.kidsmanager.at/account/link?otp=${otp}&tenant=${tenant}`;
    if (import.meta.env.MODE === 'development') {
      iframe.src += '&dev=true';
    }
    document.body.appendChild(iframe);
  };

  const handleLegacyLogin = async (event: FormEvent) => {
    event.preventDefault();
    setLoading(true);
    const state = await client.auth.authenticateUsernamePassword(
      username,
      password,
      rememberMe
    );
    if (state === 'Authenticated') {
      if (props.linkWithLegacy) {
        window.addEventListener('message', handleLinkMessage);
        const otpResponse = await client.apiOauth2.link();
        addIFrame(otpResponse.otp, otpResponse.tenant);
      } else {
        navigate('/');
      }
    } else {
      setLoading(false);
      setErrorMessage('Login fehlgeschlagen');
    }
  };

  const handleGoogleLogin = (email: string | null) => {
    const width = 600;
    const height = 700;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    const windowProps = `toolbar=no, menubar=no, width=${width}, height=${height}, top=${top}, left=${left}`;
    client.apiOauth2.googleWorkspaceUrl('USER', email).then((url) => {
      window.addEventListener('message', handleGoogleModalMessage);
      setTimeout(() => window.open(url.url, '', windowProps), 100);
    });
  };

  const handleGoogleModalMessage = async (event: {
    data: {
      success: boolean;
      tenant: string;
      token: string;
      refreshToken: string;
    };
  }) => {
    window.removeEventListener('message', handleGoogleModalMessage);
    if (event?.data?.success) {
      window.addEventListener('message', handleLinkMessage);
      client.auth.updateTokens(event.data.token, event.data.refreshToken);
      client.auth.updateTenant(event.data.tenant);
      localStorage.setItem('idp', 'google');
      if (props.linkWithLegacy) {
        const otpResponse = await client.apiOauth2.link();
        // TODO what happens if it fails -> error handling
        addIFrame(otpResponse.otp, otpResponse.tenant);
      } else {
        navigate('/');
      }
    }
  };

  const handleLinkMessage = (event: { data: { tenant: string } }) => {
    setLoading(false);
    window.removeEventListener('message', handleLinkMessage);
    const { tenant } = event.data;
    if (tenant === 'dev') {
      navigate('/');
    } else {
      window.location.href = `https://${tenant}.kidsmanager.at/`;
    }
  };

  return (
    <div className="mx-4 pt-44 text-center">
      <div className="mx-auto max-w-md px-2">
        <h1 className="flex items-center text-4xl">
          {props.brand === 'kidsmgr' && (
            <>
              <Logo format="icon" brand="kidsmgr" />
              <span className="font-bold">Kids</span>
              <span className="font-extralight">Manager</span>
            </>
          )}
          {props.brand === 'zeitmgr' && (
            <>
              <Logo format="icon" brand="zeitmgr" />
              <span className="font-bold">Zeit</span>
              <span className="font-extralight">Manager</span>
            </>
          )}
        </h1>
        <form
          className="flex max-w-md flex-col gap-2 pt-2"
          onSubmit={handleLegacyLogin.bind(this)}
        >
          <Input
            id="username"
            placeholder="Firma\Benutzername"
            value={username}
            onChange={onUsernameChange.bind(this)}
          />
          <Input
            id="password"
            placeholder="Passwort"
            type="password"
            value={password}
            onChange={setPassword.bind(this)}
          />
          <div className="text-left text-sm">
            <Checkbox
              checked={rememberMe}
              onChange={(value) => setRememberMe(value)}
              label="Angemeldet bleiben?"
            ></Checkbox>
          </div>
          <div className="flex items-center justify-end">
            <LoadSpinner show={loading}>
              <Button disabled={invalidLogindata()}>Einloggen</Button>
            </LoadSpinner>
          </div>
        </form>
        <div className="h-10">
          {errorMessage && <p className="text-error">{errorMessage}</p>}
        </div>
        <div className="mx-auto mb-5 inline-flex items-center">
          <div className="w-20 border-b border-black" />
          <div className="mx-2">oder anmelden mit</div>
          <div className="w-20 border-b border-black" />
        </div>
        <div>
          <button
            className="outline-focus inline-flex items-center gap-3 rounded-md border border-black/30 px-6 py-3 hover:bg-[#f0f4f9] active:bg-[#e9edf2]"
            onClick={() => handleGoogleLogin(null)}
          >
            <IconGoogle /> Google Workspace
          </button>
        </div>
      </div>
    </div>
  );
};
