import { UserPicker } from '@kidsmanager/ui-core';
import { useContext, useEffect, useState } from 'react';
import { IUser, IUserGroup } from '@kidsmanager/util-models';
import { ClientBackendContext } from '@kidsmanager/ui-api';

export interface TimesheetLowerHeaderProps {
  children?: React.ReactNode;
}

export const TimesheetLowerHeader = (props: TimesheetLowerHeaderProps) => {
  const [user, setUser] = useState<IUser>();
  const [groups, setGroups] = useState<IUserGroup[]>([]);
  const client = useContext(ClientBackendContext);

  useEffect(() => {
    client.users.active().then((groups) => {
      const loggedInUser = groups.flatMap((g) =>
        g.users.filter((u) => u.userId === '')
      )[0];
      if (loggedInUser) {
        setUser({ userId: '', name: loggedInUser.name });
      }
      setGroups(groups);
    });
  }, [client]);

  const onUserSelected = (user: IUser) => {
    setUser(user);
    (document.activeElement as HTMLElement)?.blur();
  };

  return (
    <div
      className={`border-silver-100 relative -m-2 mb-1 border-b bg-neutral-100 ${groups.length > 0 ? 'h-36' : 'h-32'}`}
    >
      <div className="pt-7">
        {groups.length > 0 && (
          <UserPicker
            selected={user}
            groups={groups}
            onSelected={onUserSelected.bind(this)}
          />
        )}
      </div>
      <div
        className="absolute inset-x-0 grid flex-1 grid-cols-2 items-center"
        style={{ bottom: groups.length > 0 ? '20px' : '35px' }}
      >
        {props.children}
      </div>
    </div>
  );
};
