import { useEffect, useState } from 'react';

export interface PlannedShiftProps {
  date: Date;
  children: React.ReactNode;
}

export const PlannedShift = (props: PlannedShiftProps) => {
  const [usePlannedShift, setUsePlannedShift] = useState(true);

  useEffect(() => {
    setUsePlannedShift(props.date.dateMatches(new Date()));
  }, [props.date]);

  // return !usePlannedShift ? (
  //   <div className="h-3">
  //     <div className="absolute inset-x-0 top-0 -m-2 bg-neutral-100 p-2 text-center text-sm text-black/50">
  //       Hauptdienst (H1) - Geplant von Oliver Korath
  //     </div>
  //   </div>
  // ) : (
  //   <div>{props.children}</div>
  // );
  return props.children;
};
