import { Link } from 'react-router-dom';
import { MonthDay, MonthDayProps } from './month-day';
import { useEffect, useMemo, useRef, useState } from 'react';

export interface MonthCalendarViewProps {
  date: Date;
  days: (null | string | number)[];
  selected?: Date;
  onClick?: (date?: Date) => void;
}

const tickPerDay = 24 * 60 * 60 * 1000;
const header = ['', 'M', 'T', 'W', 'T', 'F', 'S', 'S'];

export const MonthCalendarView = (props: MonthCalendarViewProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [weeks, setWeeks] = useState<
    { weekNumber: number; days: MonthDayProps[] }[]
  >([]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!ref.current) return;
      if (!ref.current.contains(event.target as Node)) {
        props.onClick?.(undefined);
      }
    };
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [props]);

  useEffect(() => {
    if (!props.days.length) return;

    const fistOfMonth = new Date(
      props.date.getFullYear(),
      props.date.getMonth(),
      1
    );

    const fistDay = fistOfMonth.firstDayOfWeek();
    const weeks = Array.from({ length: 5 }, (_, i) => {
      const startOfWeek = new Date(fistDay.getTime() + i * 7 * tickPerDay);
      const weekNumber = startOfWeek.getWeek();
      const days = Array.from({ length: 7 }, (_, j) => {
        const date = new Date(startOfWeek.getTime() + j * tickPerDay);
        const mode =
          date.getMonth() !== props.date.getMonth() ? 'disabled' : undefined;

        const value =
          date.getMonth() === props.date.getMonth()
            ? props.days[date.getDate() - 1]
            : null;

        if (value === 'FT') {
          return {
            date,
            value,
            mode,
            color: '#004a00',
            background: '#1c9e76aa'
          };
        } else if (value === 'UT') {
          return {
            date,
            value,
            mode,
            color: '#ffffff',
            background: '#1c9e76dd'
          };
        } else {
          return { date, value, mode, color: '', background: '' };
        }
      });
      return { weekNumber, days };
    });
    return setWeeks(weeks);
  }, [props.date, props.days]);

  return (
    <div ref={ref}>
      <div className="grid grid-cols-[48px_repeat(7,_auto)] gap-0.5 py-2 text-center text-xs text-black/50">
        {header.map((label, index) => (
          <div key={index}>{label}</div>
        ))}
      </div>
      {weeks.map((week, index) => (
        <div
          key={`week-${index}`}
          className="grid cursor-pointer grid-cols-[50px_auto]"
        >
          <div className="text-sm underline underline-offset-2">
            <Link
              className="outline-focus outline-offset-4"
              to={`/work/${week.days[0].date.toLocaleISODate()}/week`}
            >
              {week.weekNumber}
            </Link>
          </div>
          <div className="mb-0.5 grid grid-cols-7 gap-0.5">
            {week.days.map((day) => (
              <MonthDay
                key={day.date.getTime()}
                selected={props.selected?.getTime() === day.date.getTime()}
                onClick={props.onClick?.bind(this, day.date)}
                {...day}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
