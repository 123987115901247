import {
  Button,
  Dialog,
  DialogContext,
  LoadSpinner,
  SplitButton
} from '@kidsmanager/ui-core';
import { ISaveAs } from '@kidsmanager/util-models';
import { useContext } from 'react';
import { SaveOptionsDialog } from './save-options-dialog';

export interface ShiftSaveAsProps {
  options?: ISaveAs[];
  saving: boolean;
  onSaveAs?: (option?: ISaveAs) => void;
}

export const ShiftSaveAs = (props: ShiftSaveAsProps) => {
  const options = props.options || [];
  const dialog = useContext(DialogContext);

  const openSaveAs = () => {
    dialog.open(
      <Dialog title="Speichern als..." close={dialog.close.bind(dialog)}>
        <SaveOptionsDialog
          options={props.options}
          onClose={(value) => {
            const option = props.options?.find(
              (o) => o.name.toLowerCase() === value?.toLowerCase()
            );
            props.onSaveAs?.(option);
            dialog.close();
          }}
        />
      </Dialog>
    );
  };

  const onOptionSelected = () => {
    props.onSaveAs?.();
  };

  return (
    <div className="text-center">
      <LoadSpinner show={props.saving}>
        {options.length > 0 && (
          <SplitButton
            color="primary"
            disabled={props.saving}
            onSelect={onOptionSelected.bind(this)}
            onExpandOptions={openSaveAs.bind(this)}
          >
            <span className="inline-block p-2 uppercase tracking-widest">
              Spiechern
            </span>
          </SplitButton>
        )}
        {options.length <= 0 && (
          <Button
            color="primary"
            onClick={onOptionSelected.bind(this)}
            disabled={props.saving}
          >
            <span className="inline-block p-2 uppercase tracking-widest">
              Spiechern
            </span>
          </Button>
        )}
      </LoadSpinner>
    </div>
  );
};
