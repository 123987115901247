export interface IconButtonProps {
  children: string;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  left?: boolean;
  right?: boolean;
}

export const IconButton = (props: IconButtonProps) => {
  return (
    <button
      onClick={props.onClick}
      className={`material-icons outline-focus ${props.left || props.right ? 'absolute' : ''} z-0 cursor-pointer rounded-full p-1 hover:bg-neutral-200 active:bg-neutral-50 ${props.left ? 'left-0' : ''} ${props.right ? 'right-0' : ''}`}
      style={{ userSelect: 'none' }}
    >
      {props.children}
    </button>
  );
};
