import { UserPicker } from '@kidsmanager/ui-core';
import { useContext, useEffect, useState } from 'react';
import { ClientBackendContext } from '@kidsmanager/ui-api';
import { IUserGroup, IUser } from '@kidsmanager/util-models';

export const HolidayLowerHeader = () => {
  const [user, setUser] = useState<IUser>();
  const [groups, setGroups] = useState<IUserGroup[]>([]);
  const client = useContext(ClientBackendContext);

  useEffect(() => {
    client.users.active().then((groups) => {
      const loggedInUser = groups.flatMap((g) =>
        g.users.filter((u) => u.userId === '')
      )[0];
      if (loggedInUser) {
        setUser({ userId: '', name: loggedInUser.name });
      }
      setGroups(groups);
    });
  }, [client]);

  const onUserSelected = (user: { userId: string; name: string }) => {
    setUser(user);
    (document.activeElement as HTMLElement)?.blur();
  };

  return (
    <div className="border-silver-100 relative -mx-2 -mt-1 mb-1 border-b bg-neutral-100 py-3">
      <UserPicker
        selected={user}
        groups={groups}
        onSelected={onUserSelected.bind(this)}
      />
    </div>
  );
};
