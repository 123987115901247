import { IAccessMap, IProfile } from '@kidsmanager/util-models';
import { IAuth, fetch } from './auth';
import { BackendCache } from './backend-cache';

const cache = new BackendCache();

export class ApiAdmin {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {}

  async googleUsers(): Promise<string[]> {
    const response = await this.fetch('/api/admin/google/users');
    if (response.ok) {
      return response.json();
    }
    return [];
  }

}
